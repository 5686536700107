<template>
  <div class="tab-content">
    
    <div class="form-container--small">
      <label>Pilih Jenjang</label><br />
      <b-form-select
        v-model="formData.tryout_level_id"
        :options="levelOption"
        required
        class="mrr-22 mrb-5 my-select"
      ></b-form-select>

      <label class="mt-4">Nama Tryout</label><br />
      <b-form-input
        placeholder="Contoh: Tryout ABC"
        v-model="formData.name"
      ></b-form-input>

      <label class="mt-4">Harga Tryout</label><br />
      <b-form-input
        placeholder="100000"
        v-model="formData.price"
        type="number"
      ></b-form-input>

      <label class="mt-4">Deskripsi</label><br />
      <b-textarea
        placeholder="Tambahkan deskripsi..."
        rows="4"
        v-model="formData.description"
      ></b-textarea>

      <div class="upload-image mt-4">
        <div class="upload-image__item">
          <div class="upload-image__top">
            <label>Thumbnail</label> <br />
            <div class="cat-icon-container">
              <img
                class="image-container-img"
                :src="formData.thumbnail_src"
                alt="img"
                style="max-height:108px"
              />
              <div
                class="img-remove"
                v-show="formData.thumbnail_src != '/img/category-icons/thumbnail-default.svg'"
                @click="removeImage()"
              >
                X Remove
              </div>
            </div>
          </div>
          <div class="upload-image__bottom">
            <input
              type="file"
              :name="formData.thumbnail"
              id="inputIcon"
              class="hidden-input"
              @change="changeThumbnail()"
            />
            <button for="inputIcon" class="set-icon">
              <fa-icon icon="upload" class="my-icon" />
              UNGGAH
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-container mt-4">
      <my-button
          type="blue"
          disabled
          size="lg" 
          v-if="showLoading"
          rounded block
        >
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </my-button>
      <my-button class="mt-4" type="blue" size="lg" rounded block v-else @click="save">
        SIMPAN & TAMBAH PAKET SOAL
      </my-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      showLoading: false,
      levelOption: [],
      formData: {
        tryout_level_id: null,
        name: "",
        description: "",
        thumbnail: null,
        thumbnail_src: "/img/category-icons/thumbnail-default.svg",
        price: null,
      },
    };
  },

  mounted() {
    this.levelList();
  },

  methods: {
    ...mapActions({
      getLevelList: types.GET_TRYOUT_LEVEL_LIST,
      setNewTryout: types.SET_NEW_TRYOUT
    }),
    levelList() {
      this.getLevelList()
        .then(response => {
          var res = response;
          this.levelOption = [{value: null, text: "Pilih"}];
          for (var i = 0; i < res.length; i++) {
            var dataLevel = {
              value: res[i].id,
              text: res[i].name
            };
            this.levelOption.push(dataLevel);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    save() {
      if(this.formData.tryout_level_id && this.formData.name) {
        this.showLoading = true;
        let newFormData = new FormData();
        newFormData.append("name", this.formData.name);
        newFormData.append("description", this.formData.description);
        newFormData.append("tryout_level_id", this.formData.tryout_level_id);
        newFormData.append("thumbnail", this.formData.thumbnail);
        newFormData.append("price", this.formData.price ? this.formData.price : 0);
        this.setNewTryout(newFormData)
          .then(response => {
            this.$router.push("/tryout/edit/"+response.id);
          })
          .catch(error => {
            this.showLoading = false;
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.showMessageBox("Lengkapi data", "Failed", "success");
      }
    },
    changeThumbnail() {
      var fileData = event.target.files[0];
      this.formData.thumbnail = fileData;
      this.formData.thumbnail_src = URL.createObjectURL(fileData);
    },
    removeImage() {
      this.formData.thumbnail_src = "/img/category-icons/thumbnail-default.svg";
      this.formData.thumbnail = null;
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
        })
        .catch(err => {
        });
    }
  },
};
</script>

<style>
.cat-icon-container {
  width: 108px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9f8ff;
}
</style>
